import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { Table } from './Order';
import {
  useApproveRetailers,
  useRejectRetailers,
  useSbMartRetailers,
  useStoreTypes,
  useSuppliers,
} from './sbmartHooks';

const columns = [
  { Header: 'Customer Name', accessor: 'name' },
  { Header: 'Owner Name', accessor: (o) => o.customer.name },
  { Header: 'Phone Number', accessor: (o) => o.customer.phone, align: 'right' },
  {
    Header: 'Image Link',
    accessor: (o) =>
      o.media.length > 0 ? (
        <a target="_blank" rel="noopener noreferrer" href={o.media[0]?.value}>
          Image
        </a>
      ) : (
        ''
      ),
  },
  {
    Header: 'Address',
    accessor: (o) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          o.latitude &&
          o.longitude &&
          `http://www.google.com/maps/place/${o.latitude},${o.longitude}`
        }
      >
        {(o.address.complete_address || '') + ' ' + (o.address.landmark || '')}
      </a>
    ),
  },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Is Customer', accessor: (o) => (o.isStore ? 'true' : 'false') },
];

function Retailers() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedStoreType, setSelectedStoreType] = useState('HFS');
  const [showAssignSupplier, setShowAssignSupplier] = useState(false);

  const resetStates = () => {
    setShowAssignSupplier(false);
    setSelectedSuppliers([]);
    setSelectedRows([]);
  };

  // Queries
  const { data: suppliers = [] } = useSuppliers();
  const { data: retailers = [], status } = useSbMartRetailers();
  const { data: { storeTypes } = { storeTypes: [] } } = useStoreTypes();

  // Mutations
  const [rejectRetailers, { status: rejectStatus }] = useRejectRetailers(selectedRows, {
    onSuccess: resetStates,
  });
  const [approveRetailers, { status: approveStatus }] = useApproveRetailers(
    selectedRows,
    selectedSuppliers,
    selectedStoreType,
    { onSuccess: resetStates }
  );

  const filteredRetailers = useMemo(
    () => retailers.filter((d) => filterStatus.includes(d.status) || filterStatus.length == 0),
    [filterStatus, retailers]
  );

  const branchesOptions = useMemo(
    () =>
      suppliers.reduce((acc, sup) => {
        acc.push({ value: sup.id, label: sup.name });
        return acc;
      }, []),
    [suppliers]
  );
  const storeTypeOptions = useMemo(
    () =>
      storeTypes.reduce((acc, store) => {
        acc.push({ value: store, label: store });
        return acc;
      }, []),
    [storeTypes]
  );

  const statusOptions = useMemo(() => {
    const s = new Set();
    retailers.forEach((r) => s.add(r.status));
    return [...s.values()].map((v) => ({ value: v, label: v }));
  }, [retailers]);

  const onSelectionChange = useCallback(
    (rowIds) => {
      if (selectedRows.length == 0 && rowIds.length == 0) return;
      setSelectedRows(rowIds.map((r) => r.original.id));
    },
    [selectedRows.length]
  );

  return (
    <Box style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
      {status === 'loading' && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', height: 5 }}
          color="secondary"
        />
      )}
      <Box m={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              options={statusOptions}
              placeholder="Customer Status"
              isMulti
              onChange={(val) => setFilterStatus(val?.map((v) => v.value) || [])}
            />
          </Grid>
        </Grid>
      </Box>
      <Box px={2} style={{ overflowY: 'auto' }}>
        <Table
          columns={columns}
          data={filteredRetailers}
          on
          onSelectionChange={onSelectionChange}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
        <Box display="flex">
          <Box mx={2}>
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              disabled={!selectedRows.length || rejectStatus === 'loading'}
              onClick={() => rejectRetailers(true)}
            >
              Reject
            </Button>
          </Box>
          <Box mx={2}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={!selectedRows.length || rejectStatus === 'loading'}
              onClick={() => rejectRetailers(false)}
            >
              Outside Serviceable Area
            </Button>
          </Box>
          <Box mx={2}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={!selectedRows.length || rejectStatus === 'loading'}
              onClick={() => setShowAssignSupplier(true)}
            >
              Approve Customers
            </Button>
          </Box>
          {showAssignSupplier && (
            <Box mx={2} display="flex">
              <Box minWidth={200}>
                <Select
                  options={branchesOptions}
                  placeholder="Select Suppliers"
                  menuPlacement="top"
                  isMulti
                  onChange={(val) => setSelectedSuppliers(val || [])}
                />
              </Box>
              <Box mx={2} minWidth={200}>
                <Select
                  options={storeTypeOptions}
                  placeholder="Select Customer Type"
                  menuPlacement="top"
                  onChange={(val) => setSelectedStoreType(val.value)}
                />
              </Box>
              <Box mx={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={!selectedSuppliers.length || approveStatus === 'loading'}
                  onClick={approveRetailers}
                >
                  Done
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Box mx={1}>
            <Typography color="textSecondary">Selected:</Typography>
          </Box>
          <Typography>{selectedRows.length}</Typography>
          <Box ml={2} mr={1}>
            <Typography color="textSecondary">Total:</Typography>
          </Box>
          <Typography>{`${filteredRetailers.length} / ${retailers.length}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Retailers;
