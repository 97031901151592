import React, { useMemo } from 'react';
import Select from 'react-select';
import { a } from './orderReducer';

export default function Filter({
  options,
  // filteredOrders,
  filter: { id, name, accessor },
  dispatch,
}) {
  const structuredOptions = useMemo(() => {
    const opts = new Set();

    options.forEach((o) => {
      opts.add(accessor(o));
    });

    return [...opts.values()].map((v) => ({ value: v, label: v }));
  }, [options, accessor]);

  return (
    <Select
      options={structuredOptions}
      placeholder={name}
      isMulti
      // isOptionDisabled={(option) => !filteredOrders.find((o) => accessor(o) === option.value)}
      // isOptionDisabled={(option) => true}
      onChange={(val) =>
        dispatch([a.FILTER_CHANGE, { id, value: val ? val.map((v) => v.value) : [] }])
      }
    />
  );
}
