import produce from 'immer';

export const filters = [
  {
    id: 'orderStatus',
    name: 'Order Status',
    accessor: (o) => o.status,
    filter: (o, val) => val.includes(o.status),
  },
  {
    id: 'retailerCode',
    name: 'Customer Code',
    accessor: (o) => o.subscription.outlet.retailerCode,
    filter: (o, val) => val.includes(o.subscription.outlet.retailerCode),
  },
  {
    id: 'outletType',
    name: 'Customer Type',
    accessor: (o) => o.subscription.outletType.store_type,
    filter: (o, val) => val.includes(o.subscription.outletType.store_type),
  },
];

export const initialState = {
  orders: [],
  filteredOrders: [],
  filters: {
    // orderStatus: [],
    // retailerCode: [],
  },
};

const orderReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case a.INIT:
        draft.orders = data;
        break;
      case a.FILTER_CHANGE:
        draft.filters[data.id] = data.value;
        break;
      case a.UPDATE_ORDER_STATUS:
        draft.orders = draft.orders.map((order) => {
          return data.find((o) => o.id === order.id) || order;
        });
        break;
    }

    if ([a.INIT, a.FILTER_CHANGE, a.UPDATE_ORDER_STATUS].includes(action)) {
      draft.filteredOrders = filterOrders(
        draft.orders,
        filters.map((f) => ({
          filter: f.filter,
          value: draft.filters[f.id],
        }))
      );
    }
  });
};

const filterOrders = (orders, filters) => {
  return orders.filter(
    (o) => !filters.some((f) => (f.value?.length ? !f.filter(o, f.value) : false))
  );
};

export const a = {
  INIT: 'INIT',
  FILTER_CHANGE: 'FILTER_CHANGE',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
};

export default orderReducer;
