import { queryCache, useMutation, useQuery } from 'react-query';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';

export function usePrincipal() {
  return useQuery('principal', () => fetch('/sbmart/principal'));
}

export function useStoreTypes() {
  const { branch } = useGState((s) => ({ branch: s.branch }));
  return useQuery(branch && ['store_types', branch.id], (_, branchId) =>
    fetch(`/bsbmart/store-type?nodeId=${branchId}`)
  );
}
export function useSuppliers() {
  const { data: principalId } = usePrincipal();
  return useQuery(principalId && 'suppliers', () =>
    fetch(`/sbmart/suppliers?principal_id=${principalId}`)
  );
}
export function useSbMartRetailers() {
  const { data: principalId } = usePrincipal();
  return useQuery(principalId && 'sbmart_retailers', () =>
    fetch(`/sbmart/pending/outlets?principal_id=${principalId}`)
  );
}

// Mutations

export function useRejectRetailers(selectedRows, { onSuccess, onError }) {
  const { data: principalId } = usePrincipal();
  return useMutation(
    (edit = false) =>
      fetch({
        method: 'POST',
        url: `/sbmart/outlet/${edit ? 'edit' : 'reject'}`,
        data: {
          outletIds: selectedRows,
          principalId,
        },
      }),
    {
      onError: () => {
        onError && onError();
      },
      onSuccess: () => onSuccess(),
      onSettled: () => {
        queryCache.refetchQueries('sbmart_retailers');
      },
    }
  );
}
export function useApproveRetailers(
  selectedRows,
  selectedSuppliers,
  selectedStoreType,
  { onSuccess, onError }
) {
  const { data: principalId } = usePrincipal();

  return useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/sbmart/outlet/accept`,
        data: {
          outletIds: selectedRows,
          supplierIds: selectedSuppliers.map((s) => s.value.toString()),
          outletType: { store_type: selectedStoreType },
          principalId,
        },
      }),
    {
      onError: () => {
        onError && onError();
      },
      onSuccess: () => onSuccess(),
      onSettled: () => {
        queryCache.refetchQueries('sbmart_retailers');
      },
    }
  );
}
