import React from 'react';
import Layout from 'components/Layout';
import Retailers from 'sbmart/Retailers';
import { ReactQueryConfigProvider } from 'react-query';

export default (props) => {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Retailers />
      </Layout>
    </ReactQueryConfigProvider>
  );
};
