import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import CheckboxMUI from '@material-ui/core/Checkbox';
import TableMUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { saveAs } from 'file-saver';
import React, { memo, useCallback, useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { useRowSelect, useTable } from 'react-table';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import { formatCurrency, isBranch } from 'utils/utils';
import Filter from './Filter';
import orderReducer, { a, filters, initialState } from './orderReducer';

const Checkbox = ({ ...rest }) => (
  <CheckboxMUI size="small" color="primary" disableRipple {...rest} />
);

export const Table = memo(function Table({ columns, data, onSelectionChange }) {
  const { getTableProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          padding: 'checkbox',
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (onSelectionChange) onSelectionChange(selectedFlatRows);
  }, [onSelectionChange, selectedFlatRows]);

  return (
    <TableMUI size="small" {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                {...column.getHeaderProps()}
                align={column.align || 'left'}
                padding={column.padding || 'default'}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell
                    {...cell.getCellProps()}
                    align={cell.column.align || 'left'}
                    padding={cell.column.padding || 'default'}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </TableMUI>
  );
});

const columns = [
  {
    Header: 'Order Id',
    accessor: 'id',
  },
  {
    Header: 'Order Status',
    accessor: 'status',
  },
  {
    Header: 'Customer Name',
    accessor: (o) => o.subscription.outlet.name,
  },
  {
    Header: 'Customer Code',
    accessor: (o) => o.subscription.outlet.retailerCode,
  },
  {
    Header: 'Amount',
    accessor: 'totalAmount',
    align: 'right',
    Cell: (props) => formatCurrency(props.value),
  },
  {
    Header: 'Order Date',
    accessor: 'placedTime',
    Cell: (props) => formatDate(props.value),
  },
];

const stateOptions = [
  'CONFIRMED',
  'PACKED',
  'OUT_FOR_DELIVERY',
  'DELIVERED',
  'SUPPLIER_CANCELLED',
  'HOLD',
  'ATTEMPTED',
].map((s) => ({ label: s, value: s }));

function Order() {
  const { branch } = useGState((s) => ({ date: s.date, branch: s.branch }));
  const [dateRange, setDateRange] = useState([]);

  const [selectedState, setSelectedState] = useState();

  const [
    {
      orders,
      filteredOrders,
      filters: { orderStatus, retailerCode, outletType },
    },
    dispatch,
  ] = useReducer(orderReducer, initialState);
  const [selectedRows, setSelectedRows] = useState([]);

  const { status } = useQuery(
    Boolean(isBranch(branch) && dateRange.length) && [
      'sbmart_orders',
      dateRange[0],
      dateRange[1],
      branch?.id,
    ],
    (_, start_date, end_date, branchId) =>
      fetch(`/sbmart/orders?start_date=${start_date}&end_date=${end_date}&supplier_id=${branchId}`),
    {
      onSuccess: (data) => {
        dispatch([a.INIT, data]);
      },
    }
  );

  const [changeOrderState, { status: updateStatus }] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/sbmart/orders/change/state?supplier_id=${branch?.id}`,
        data: {
          orderIds: selectedRows,
          state: selectedState,
        },
      }),
    {
      onSuccess: (data) => {
        dispatch([a.UPDATE_ORDER_STATUS, data]);
      },
    }
  );

  const [downloadOrders] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/sbmart/orders/filter?supplier_id=${branch?.id}`,
        responseType: 'blob',
        data: {
          states: orderStatus ?? [],
          retailerCodes: retailerCode ?? [],
          orderIds: [],
          outletTypes: outletType?.map((ot) => ({ store_type: ot })) ?? [],
          startDate: dateRange[0],
          endDate: dateRange[1],
        },
      }),
    {
      onSuccess: (data) => {
        saveAs(data, `SBMart_Orders.xlsx`);
      },
    }
  );

  const onSelectionChange = useCallback(
    (rowIds) => {
      setSelectedRows(rowIds.map((r) => r.original.id));
    },
    [setSelectedRows]
  );

  return (
    <Box style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
      {status === 'loading' && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', height: 5 }}
          color="secondary"
        />
      )}
      <Box m={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <DateTimePicker onChange={(dateRange) => setDateRange(dateRange)} />
          </Grid>
          {filters.map((filter) => (
            <Grid item xs={12} sm={6} md={3} key={filter.id}>
              <Filter {...{ filter, options: orders, dispatch }} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box px={2} style={{ overflowY: 'auto' }}>
        <Table columns={columns} data={filteredOrders} on onSelectionChange={onSelectionChange} />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
        <Box display="flex">
          <Box mx={2}>
            <Select
              options={stateOptions}
              className="w-44"
              placeholder={'Select Status'}
              onChange={(v) => setSelectedState(v.value)}
              isDisabled={!selectedRows.length || updateStatus === 'loading'}
              menuPlacement="top"
            />
          </Box>
          <Box mx={2}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={!selectedRows.length || updateStatus === 'loading' || !selectedState}
              onClick={changeOrderState}
            >
              Confirm
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mx={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={!orders.length}
              onClick={downloadOrders}
            >
              Download Orders
            </Button>
          </Box>
          <Box mx={1}>
            <Typography color="textSecondary">Selected:</Typography>
          </Box>
          <Typography>{selectedRows.length}</Typography>
          <Box ml={2} mr={1}>
            <Typography color="textSecondary">Total:</Typography>
          </Box>
          <Typography>{`${filteredOrders.length} / ${orders.length}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Order;

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
